// components/layout/PageLayout.js
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import RentalInquiryCTA from "@/components/layout/RentalInquiryCTA";
import GoogleAnalytics from "@/components/layout/GoogleAnalytics";

export default function PageLayout({ children, nonce }) {
	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<main className="flex-grow">{children}</main>
			<RentalInquiryCTA />
			<Footer />
			<GoogleAnalytics nonce={nonce} />
		</div>
	);
}
