import { useEffect } from "react";

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID;

const GoogleAnalytics = ({ nonce }) => {
	useEffect(() => {
		if (GA_MEASUREMENT_ID && nonce) {
			// Create and append the external script
			const externalScript = document.createElement("script");
			externalScript.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
			externalScript.async = true;
			externalScript.nonce = nonce;
			document.head.appendChild(externalScript);

			// Create and append the inline script
			const inlineScript = document.createElement("script");
			inlineScript.nonce = nonce;
			inlineScript.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '${GA_MEASUREMENT_ID}', {
				page_path: window.location.pathname,
				});
			`;
			document.head.appendChild(inlineScript);
		}
	}, [nonce]);

	return null; // This component doesn't render anything
};

export default GoogleAnalytics;
