// components/layout/Header.js
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";

export default function Header() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const router = useRouter();

	const linkClass = (path) => {
		const baseClasses = "hover:bg-purple-700 py-2 px-4 block"; // Ensure block display for mobile
		if (router.pathname === path) {
			return `${baseClasses} text-white font-bold`;
		}
		return `${baseClasses} text-white`;
	};

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<nav id="main-header" className="bg-purple fixed w-full z-10 transition-all duration-300 ease-in-out">
			<div className="container mx-auto flex justify-between items-center py-6 px-4">
				{" "}
				{/* Increased padding for taller header */}
				<Link href="/" className="text-lg text-white" onClick={() => setIsMenuOpen(false)}>
					{/* IMPORTANT - Change this for new client site */}
					RIVERVIEW RESIDENCES
				</Link>
				<div className="md:hidden">
					<button onClick={toggleMenu} className="text-white focus:outline-none" aria-label="Toggle menu">
						<div className="relative w-6 h-6">
							<span
								className={`block absolute h-0.5 w-full bg-white transform transition duration-300 ease-in-out ${
									isMenuOpen ? "rotate-45 top-2.5" : "rotate-0 top-1"
								}`}
							></span>
							<span
								className={`block absolute h-0.5 w-full bg-white transform transition duration-300 ease-in-out ${
									isMenuOpen ? "opacity-0" : "opacity-100 top-2.5"
								}`}
							></span>
							<span
								className={`block absolute h-0.5 w-full bg-white transform transition duration-300 ease-in-out ${
									isMenuOpen ? "-rotate-45 top-2.5" : "rotate-0 top-4"
								}`}
							></span>
						</div>
					</button>
				</div>
				<div
					className={`md:hidden ${isMenuOpen ? "block" : "hidden"} absolute top-full left-0 w-full bg-purple shadow-md`}
				>
					<div className="mb-4">
						<Link href="/" className={linkClass("/")} onClick={() => setIsMenuOpen(false)}>
							HOME
						</Link>
						<Link href="/suites" className={linkClass("/suites")} onClick={() => setIsMenuOpen(false)}>
							SUITES
						</Link>
						<Link href="/amenities" className={linkClass("/amenities")} onClick={() => setIsMenuOpen(false)}>
							AMENITIES
						</Link>
						<Link href="/gallery" className={linkClass("/gallery")} onClick={() => setIsMenuOpen(false)}>
							GALLERY
						</Link>
						<Link href="/location" className={linkClass("/location")} onClick={() => setIsMenuOpen(false)}>
							LOCATION
						</Link>
						<Link href="/contact" className={linkClass("/contact")} onClick={() => setIsMenuOpen(false)}>
							CONTACT
						</Link>
					</div>
				</div>
				<div className="hidden md:flex space-x-4">
					<Link href="/" className={linkClass("/")}>
						HOME
					</Link>
					<Link href="/suites" className={linkClass("/suites")}>
						SUITES
					</Link>
					<Link href="/amenities" className={linkClass("/amenities")}>
						AMENITIES
					</Link>
					<Link href="/gallery" className={linkClass("/gallery")}>
						GALLERY
					</Link>
					<Link href="/location" className={linkClass("/location")}>
						LOCATION
					</Link>
					<Link href="/contact" className={linkClass("/contact")}>
						CONTACT
					</Link>
				</div>
			</div>
		</nav>
	);
}
