// components/forms/RentalInquiryModal.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import SuccessMessage from "@/components/feedback/SuccessMessage";
import ReCAPTCHA from "react-google-recaptcha";
import { useRentalInquiryForm } from "@/hooks/useRentalInquiryForm";
import { useRentalInquiryModalState } from "@/hooks/useRentalInquiryModalState";
import { useMetaInformationQuery } from "@/hooks/queries/useMetaInformationQuery";
import InputMask from "react-input-mask";

export default function RentalInquiryModal({
	isOpenRentalInquiryModal,
	onCloseRentalInquiryModal,
	initialRentalInquiryUnitType,
}) {
	const { data } = useMetaInformationQuery();
	const unitTypes = data?.unit_types;

	// Hook to manage form state and submission, now passing available unit types for default selection
	const { register, handleSubmit, control, errors, onSubmit, recaptchaRef, isSubmitting, formSubmitted, reset } =
		useRentalInquiryForm({
			initialRentalInquiryUnitType,
			isOpenRentalInquiryModal,
			availableUnitTypes: unitTypes, // Passing available unit types from API response
		});

	// Hook to manage modal open/close state and effects
	useRentalInquiryModalState({ isOpenRentalInquiryModal, onCloseRentalInquiryModal, reset });

	if (!isOpenRentalInquiryModal) return null;

	return (
		<div
			className="modalBackdrop fixed inset-0 bg-black bg-opacity-100 z-50 flex justify-center items-center p-4"
			aria-modal="true"
			role="dialog"
			aria-labelledby="rental-inquiry-modal-heading"
		>
			<div className="bg-off-white p-8 rounded-lg w-full sm:w-3/4 md:w-1/2 max-w-lg relative animate-drop-down overflow-auto max-h-full text-left">
				<button
					onClick={onCloseRentalInquiryModal}
					className="absolute top-2 right-2 font-medium text-purple hover:text-black text-2xl"
					aria-label="Close rental inquiry form"
				>
					&times;
				</button>
				{/* IMPORTANT - Change this for new client site, the h2 below */}
				<h2 id="rental-inquiry-modal-heading" className="text-2xl font-bold text-purple mb-4">
					Rent at Riverview Residences
				</h2>
				{formSubmitted ? (
					<div>
						<SuccessMessage />
					</div>
				) : (
					<div>
						<p className="mb-4 text-black">
							Please complete the contact form, and we will contact you as soon as possible. Fields marked with{" "}
							<span className="text-purple font-semibold">*</span> are required.
						</p>
						<form className="mt-4 text-black" onSubmit={handleSubmit(onSubmit)}>
							{unitTypes && (
								<div className="mb-4">
									<label htmlFor="suiteInterest" className="block font-medium text-black">
										Unit Type:
									</label>
									<select
										id="suiteInterest"
										name="suiteInterest"
										className="block w-full mt-2 p-2 rounded border border-grey bg-white"
										defaultValue={initialRentalInquiryUnitType}
										{...register("suiteInterest")}
										placeholder="Your Unit Type Interest"
									>
										{unitTypes.map((unit_type) => (
											<option key={unit_type.unit_type} value={unit_type.unit_type_for_url}>
												{unit_type.unit_type} - {unit_type.starting_price_or_availability}
											</option>
										))}
									</select>
								</div>
							)}

							<div className="mb-4">
								<label htmlFor="preferredMoveInDate" className="block font-medium text-black">
									Preferred Move-In Date:
								</label>
								<div className="mt-2">
									{" "}
									{/* Apply margin here, since adding it on the Controller or DatePicker throws things off */}
									<Controller
										control={control}
										name="preferredMoveInDate"
										defaultValue={null}
										render={({ field }) => (
											<DatePicker
												id="preferredMoveInDate"
												selected={field.value}
												onChange={(date) => field.onChange(date)}
												// className="relative block w-full mt-2 p-2 pr-10 rounded border border-grey bg-white"
												className="block w-full p-2 rounded border border-grey bg-white"
												isClearable
												popperPlacement="top-end"
												minDate={new Date()}
												aria-describedby="move-in-date-description"
												placeholderText="Your Preferred Move-In Date"
											/>
										)}
									/>
								</div>
								<span id="rental-date-description" className="sr-only">
									Select a move-in date
								</span>
							</div>

							<div className="mb-4">
								<label htmlFor="fullName" className="block font-medium text-black">
									Full Name
									<span className="text-purple font-semibold" aria-hidden="true">
										*
									</span>
									:
								</label>
								<input
									type="text"
									id="fullName"
									name="fullName"
									className="block w-full mt-2 p-2 rounded border border-grey bg-white"
									aria-required="true"
									placeholder="Your Full Name"
									{...register("fullName")}
								/>
								{errors.fullName && <p className="text-red-600 mt-2">{errors.fullName.message}</p>}
							</div>

							<div className="mb-4">
								<label htmlFor="email" className="block font-medium text-black">
									Email Address
									<span className="text-purple font-semibold" aria-hidden="true">
										*
									</span>
									:
								</label>
								<input
									type="email"
									id="email"
									name="email"
									className="block w-full mt-2 p-2 rounded border border-grey bg-white"
									aria-required="true"
									placeholder="Your Email"
									{...register("email")}
								/>
								{errors.email && <p className="text-red-600 mt-2">{errors.email.message}</p>}
							</div>

							<div className="mb-4">
								<label htmlFor="phoneNumber" className="block font-medium text-black">
									Phone Number
									<span className="text-purple font-semibold" aria-hidden="true">
										*
									</span>
									:
								</label>
								<Controller
									name="phoneNumber"
									control={control}
									rules={{
										required: "Phone number is required",
										pattern: {
											value: /^\(\d{3}\) \d{3}-\d{4}$/,
											message: "Phone number must be in the format (123) 456-7890",
										},
									}}
									render={({ field }) => (
										<InputMask
											{...field}
											mask="(999) 999-9999"
											maskChar={null}
											id="phoneNumber"
											className="block w-full mt-2 p-2 rounded border border-grey bg-white"
											placeholder="Your Phone Number"
											// Below this makes it format properly so it doesn't include a "(" before any numbers are types
											beforeMaskedValueChange={(newState) => {
												let { value } = newState;
												if (value === "(") {
													value = "";
												}
												return {
													...newState,
													value,
												};
											}}
										/>
									)}
								/>
								{errors.phoneNumber && <p className="text-red-600 mt-2">{errors.phoneNumber.message}</p>}
							</div>

							<div className="mb-2">
								<label htmlFor="message" className="block font-medium text-black">
									Message:
								</label>
								<textarea
									id="message"
									name="message"
									rows="4"
									className="block w-full mt-2 p-2 rounded border border-grey bg-white"
									placeholder="Your Message"
									{...register("message")}
								></textarea>
							</div>

							<div className="mb-4">
								<ReCAPTCHA ref={recaptchaRef} sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} size="invisible" />
							</div>
							<button
								type="submit"
								className="w-full mt-4 px-4 py-2 bg-purple text-white font-semibold rounded hover:bg-purple-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple"
								aria-label="Submit rental inquiry form"
								disabled={isSubmitting}
							>
								{isSubmitting ? "Submitting..." : "Send Message"}
							</button>
						</form>
					</div>
				)}
			</div>
		</div>
	);
}
