// components/layout/RentalInquiryCTA.js
import React, { useState } from "react";
import RentalInquiryModal from "@/components/forms/RentalInquiryModal";

const RentalInquiryCTA = () => {
	const [isOpenRentalInquiryModal, setIsOpenRentalInquiryModal] = useState(false);

	const openRentalInquiryModal = () => setIsOpenRentalInquiryModal(true);
	const closeRentalInquiryModal = () => setIsOpenRentalInquiryModal(false);

	return (
		<div className="bg-purple text-white text-center py-12 px-5">
			<h2 className="text-2xl font-bold mb-4">Interested in Renting?</h2>
			<button
				onClick={openRentalInquiryModal}
				className="font-bold py-3 px-6 rounded bg-white text-purple transition-all duration-300 transform hover:scale-105 hover:shadow-lg"
			>
				Inquire Now
			</button>
			{/* Always render RentalInquiryModal, but it will only be loaded when isOpenModal is true */}
			<RentalInquiryModal
				isOpenRentalInquiryModal={isOpenRentalInquiryModal}
				onCloseRentalInquiryModal={closeRentalInquiryModal}
			/>
		</div>
	);
};

export default RentalInquiryCTA;
