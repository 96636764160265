// components/feedback/SuccessMessage.js
const SuccessMessage = () => (
	<div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative w-full" role="alert">
		<strong className="block font-bold">Message sent successfully!</strong>
		<br />
		<span className="block">We will respond to your message as soon as possible.</span>
	</div>
);

export default SuccessMessage;
