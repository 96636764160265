// src/hooks/queries/useMetaInformationQuery.js
import { useQuery } from "@tanstack/react-query";
import { getMetaInformation } from "@/services/getMetaInformation";

export const useMetaInformationQuery = () => {
	const propertyPublicId = process.env.NEXT_PUBLIC_PROPERTY_PUBLIC_ID;

	return useQuery({
		queryKey: ["metaInformation", propertyPublicId],
		queryFn: () => getMetaInformation({ propertyPublicId }),
		enabled: !!propertyPublicId,
		staleTime: 60 * 60 * 1000, // 60 minutes in milliseconds
		cacheTime: 5 * 60 * 1000, // 5 minutes in milliseconds
	});
};
