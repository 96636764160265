// src/hooks/useRentalInquiryForm.js
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { rentalInquiryValidationSchema } from "@/utils/validationSchemas";
import { useReCAPTCHA } from "@/hooks/useReCAPTCHA";
import { useFormSubmission } from "@/hooks/useFormSubmission";

export const useRentalInquiryForm = ({
	initialRentalInquiryUnitType,
	isOpenRentalInquiryModal,
	availableUnitTypes = [],
}) => {
	const [formSubmitted, setFormSubmitted] = useState(false); // Added formSubmitted state
	const { recaptchaRef, executeReCAPTCHA } = useReCAPTCHA();
	const { submitForm, isSubmitting, error } = useFormSubmission({
		apiEndpoint: "/api/send-contact-form-submission",
		onSuccess: () => {
			setFormSubmitted(true);
			sessionStorage.removeItem("rentalInquiryFormData");
		},
	});

	// Set a default unit type if `initialRentalInquiryUnitType` is undefined
	const defaultUnitType =
		initialRentalInquiryUnitType ||
		availableUnitTypes.find((unit) => unit.units.length > 0)?.unit_type_for_url ||
		"1-bedroom";

	const {
		register,
		handleSubmit,
		control,
		formState: { errors = {} },
		watch,
		reset,
	} = useForm({
		resolver: yupResolver(rentalInquiryValidationSchema),
	});

	useEffect(() => {
		// Initializes form from session storage or default values
		const initializeFormData = () => {
			const storedFormData = sessionStorage.getItem("rentalInquiryFormData");
			const storedData = storedFormData ? JSON.parse(storedFormData) : null;
			if (storedData) {
				reset({
					fullName: storedData.fullName || "",
					email: storedData.email || "",
					phoneNumber: storedData.phoneNumber || "",
					comments: storedData.comments || "",
					suiteInterest: storedData.suiteInterest || defaultUnitType,
					moveInDate: storedData.moveInDate ? new Date(storedData.moveInDate) : null,
				});
			} else {
				reset({
					fullName: "",
					email: "",
					phoneNumber: "",
					comments: "",
					suiteInterest: defaultUnitType,
					moveInDate: null,
				});
			}
		};

		if (isOpenRentalInquiryModal) {
			initializeFormData();
		}
	}, [isOpenRentalInquiryModal, defaultUnitType, reset]);

	// Session storage management
	useEffect(() => {
		const storeFormData = () => {
			if (isOpenRentalInquiryModal) {
				sessionStorage.setItem(
					"rentalInquiryFormData",
					JSON.stringify({
						fullName: watch("fullName"),
						email: watch("email"),
						phoneNumber: watch("phoneNumber"),
						comments: watch("comments"),
						suiteInterest: watch("suiteInterest"),
						moveInDate: watch("moveInDate") ? watch("moveInDate").toISOString() : null,
					})
				);
			}
		};

		const handleVisibilityChange = () => {
			if (document.visibilityState === "hidden") {
				storeFormData();
			}
		};

		const handleBeforeUnload = () => {
			storeFormData();
		};

		const handleBlur = () => {
			storeFormData();
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);
		window.addEventListener("beforeunload", handleBeforeUnload);
		window.addEventListener("blur", handleBlur);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
			window.removeEventListener("beforeunload", handleBeforeUnload);
			window.removeEventListener("blur", handleBlur);
		};
	}, [isOpenRentalInquiryModal, watch]); // Added `watch` to the dependency array

	const onSubmit = async (formData) => {
		const { success } = await executeReCAPTCHA();
		if (!success) return;
		await submitForm(formData);
	};

	// Consolidate error handling in the hook
	useEffect(() => {
		if (error) {
			alert("An error occurred. Please try again.");
		}
	}, [error]);

	return { register, handleSubmit, control, errors, onSubmit, recaptchaRef, isSubmitting, formSubmitted, reset };
};
