// src/hooks/useRentalInquiryModalState.js
import { useEffect } from "react";

export const useRentalInquiryModalState = ({ isOpenRentalInquiryModal, onCloseRentalInquiryModal, reset }) => {
	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (isOpenRentalInquiryModal && e.target.classList.contains("modalBackdrop")) {
				onCloseRentalInquiryModal();
			}
		};

		window.addEventListener("click", handleOutsideClick);
		return () => window.removeEventListener("click", handleOutsideClick);
	}, [isOpenRentalInquiryModal, onCloseRentalInquiryModal]);

	useEffect(() => {
		if (!isOpenRentalInquiryModal) {
			// Reset the form when the modal closes
			reset();
		}
	}, [isOpenRentalInquiryModal, reset]);
};
