// pages/_app.js
import "@/styles/globals.css";
import { QueryClient, QueryClientProvider, hydrate } from "@tanstack/react-query";
import PageLayout from "@/components/layout/PageLayout";
import { useState, useEffect } from "react";

function MyApp({ Component, pageProps }) {
	const [queryClient] = useState(() => new QueryClient());
	const [nonce, setNonce] = useState("");

	useEffect(() => {
		const metaNonce = document.querySelector("meta[name='csp-nonce']");
		if (metaNonce) {
			setNonce(metaNonce.getAttribute("content"));
		}
	}, []);

	// Hydrate the query client with the initial state if it exists
	useEffect(() => {
		if (pageProps.dehydratedState) {
			hydrate(queryClient, pageProps.dehydratedState);
		}
	}, [pageProps.dehydratedState, queryClient]);

	return (
		<QueryClientProvider client={queryClient}>
			<PageLayout nonce={nonce}>
				<Component {...pageProps} />
			</PageLayout>
		</QueryClientProvider>
	);
}

export default MyApp;
