// hooks/useReCAPTCHA.js
import { useRef } from "react";

export const useReCAPTCHA = () => {
	const recaptchaRef = useRef();

	const executeReCAPTCHA = async () => {
		try {
			const recaptchaToken = await recaptchaRef.current.executeAsync();
			if (!recaptchaToken) {
				alert("Please complete the reCAPTCHA");
				return { success: false };
			}

			const recaptchaResponse = await fetch("/api/validate-recaptcha", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ token: recaptchaToken }),
			});

			const recaptchaData = await recaptchaResponse.json();
			if (!recaptchaData.success) {
				alert("reCAPTCHA validation failed. Please try again.");
				return { success: false };
			}

			return { success: true };
		} catch (error) {
			console.error("reCAPTCHA validation error:", error);
			alert("An error occurred during reCAPTCHA validation. Please try again.");
			return { success: false };
		}
	};

	return {
		recaptchaRef,
		executeReCAPTCHA,
	};
};
